import DatePicker from 'vue2-datepicker';
import appStrings from '@/app/utility/string.utility.js';
export default {
  name: 'addtdsPartySetup',
  props: ['selectedTaxSectionData', 'rowData'],
  components: {
    DatePicker
  },
  watch: {},
  data() {
    return {
      categoryName: {
        text: null,
        value: null
      },
      categoryVset: {
        text: null,
        value: null
      },
      endDate: null,
      startDate: null,
      ltdc: false,
      unregistered: false,
      showValueSetModal: false,
      categoryVsetOptions: [
        {
          value: 'COMP',
          text: 'Company'
        },
        {
          text: 'Individual',
          value: 'INDV'
        }
      ],
      editMode: true,
      setUpHdrId: null,
      loader: false
    };
  },
  mounted() {
    if (this.rowData) {
      this.setUpHdrId = this.rowData.tds_setup_hdr_id;
      if (this.setUpHdrId) {
        this.getTdsPartySetupById();
        this.editMode = false;
      } else {
        this.editMode = true;
      }
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'save' && !this.addPartySetupDetails) {
          this.postTdsPartySetup();
        }
        if (actionName === 'update' && !this.showTaxSectionDetails) {
          this.editMode = true;
        }
      }
    });
  },
  methods: {
    openValueSetModal(vsetCode) {
      this.vsetCode = vsetCode;
      this.parent_value_set_id = null;
      this.showValueSetModal = true;
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.TDS_TAX_CATEGORY_ACTUAL) {
        this.categoryName.text = item.category_name;
        this.categoryName.value = item.tax_cat_hdr_id;
      }
    },
    postTdsPartySetup() {
      if (
        this.categoryName.text === null ||
        this.categoryVset.text === null ||
        this.startDate === null
      ) {
        this.$bvToast.toast('Please Select Mandatory Fields', {
          title: 'Alert',
          variant: 'success',
          solid: true
        });
      } else {
        const payload = {
          tax_section_id: this.selectedTaxSectionData.tax_section_id,
          ltdc: this.ltdc,
          tax_cat_id: this.categoryName.value,
          unregistered: this.unregistered,
          party_cat_vset: this.categoryVset.text,
          start_date: this.startDate,
          end_date: this.endDate,
          tds_setup_hdr_id: this.setUpHdrId ? this.setUpHdrId : null
        };
        this.loader = true;
        this.$store
          .dispatch('defineTaxSection/postTdsPartySetup', payload)
          .then(resp => {
            this.loader = false;
            if (resp.status === 201) {
              this.editMode = false;
              this.$bvToast.toast(resp.data.message, {
                title: 'Alert',
                variant: 'success',
                solid: true
              });
            } else {
              this.editMode = false;
              this.$bvToast.toast(resp.response.data.message, {
                title: 'Alert',
                variant: 'danger',
                solid: true
              });
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
    },
    getTdsPartySetupById() {
      const payload = {
        tds_setup_hdr_id: this.setUpHdrId
      };
      this.loader = true;
      this.$store
        .dispatch('defineTaxSection/getTdsPartySetupById', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.editMode = false;
            const reslut = response.data.data;
            this.categoryName.text = reslut.category_name;
            this.categoryName.value = reslut.tax_cat_id;
            this.categoryVset.value = reslut.meaning;
            this.categoryVset.text = reslut.party_cat_vset;
            this.startDate = reslut.start_date;
            this.endDate = reslut.end_date;
            this.ltdc = reslut.ltdc;
            this.unregistered = reslut.unregistered;
          }
        })
        .catch(() => {
          alert('Something went Wrong.');
        });
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
