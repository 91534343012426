import appStrings from '@/app/utility/string.utility';
import commonHelper from '@/app/utility/common.helper.utility';
import party from '@/app/components/generalLedger/party';
import { ModelSelect } from 'vue-search-select';
import Datepicker from 'vuejs-datepicker';
import Taxgroup from '../../taxgroup';
import addtdsPartySetup from './addtdsPartySetup';
export default {
  name: 'TaxSectionDetails',
  props: ['selectedTaxSectionData'],
  components: {
    ModelSelect,
    Datepicker,
    Taxgroup,
    party,
    addtdsPartySetup
  },
  watch: {
    currentPage: function() {
      this.getTaxSectionById();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getTaxSectionById();
    }
  },
  data() {
    return {
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      currentPage: 1,
      totalRows: null,
      showTaxSectionDetails: false,
      showTaxGroupList: false,
      showValueSetModal: false,
      parent_value_set_id: null,
      index: null,
      loader: false,
      editMode: false,
      updateMode: false,
      addMode: false,
      showAlert: false,
      isSuccess: false,
      unsubscribe: null,
      responseMsg: '',
      form: {
        taxGroup: null,
        taxGroupId: 0,
        section: null,
        sectionId: 0,
        status: true
      },
      taxSectionDetails: [
        {
          tax_section_id: 0,
          tax_section_site_mpng_id: 0,
          le_id: null,
          le_name: null,
          entity_id: null,
          entity_name: null,
          entity_type: 'LOC',
          vendor_id: null,
          vendor_name: null,
          vendor_site_id: null,
          vendor_site: null,
          tds_clearing_ccid: null,
          tds_clearing_ccid_meaning: null
        }
      ],
      taxSectionFields: [
        {
          key: 'add',
          stickyColumn: true
        },
        {
          key: 'remove',
          stickyColumn: true
        },
        {
          key: 'le_name',
          label: 'Legal Entity'
        },
        {
          key: 'entity_type'
        },
        {
          key: 'entity_name'
        },
        {
          key: 'vendor_name'
        },
        {
          key: 'vendor_site'
        },
        {
          key: 'tds_clearing_ccid_meaning'
        }
      ],
      showPartyModal: false,
      activeTab: 'clearing',
      tdsPartySetupDetails: [],
      tdsPartySetupFields: [
        {
          key: 'category_name',
          label: 'Category Name ',
          class: 'col-fix text-center'
        },
        {
          key: 'meaning',
          label: 'Party Category ',
          class: 'col-fix text-center'
        },
        {
          key: 'unregistered',
          label: 'UnRegistered',
          class: 'col-fix text-center'
        },
        {
          key: 'ltdc',
          label: 'LTDC',
          class: 'col-fix text-center'
        },
        {
          key: 'start_date',
          class: 'col-fix text-center'
        },
        {
          key: 'end_date',
          class: 'col-fix text-center'
        }
      ],
      addPartySetupDetails: false,
      accessButtons: {
        deleteAllow: false,
        downloadAllow: false,
        uploadAllow: false,
        editAllow: true,
        addAllow: true,
        printAllow: false,
        approvalAllow: false
      },
      rowData: null
    };
  },
  mounted() {
    if (this.selectedTaxSectionData) {
      this.setFormData(this.selectedTaxSectionData);
      this.addMode = false;
      this.getTdsPartySetup();
    } else {
      this.updateMode = true;
      this.showAlert = false;
      this.editMode = true;
      this.addMode = true;
    }
    this.unsubscribe = this.$store.subscribe((mutation, state) => {
      if (mutation.type === 'shared/setActionName') {
        const actionName = state.shared.actionName;
        if (actionName === 'update' && !this.addPartySetupDetails) {
          this.editMode = false;
          this.updateMode = true;
        }
        if (actionName === 'save' && this.updateMode) {
          this.addEditTaxSection();
        }
        if (actionName === 'upload') {
          this.eventBus.$emit('commonUpload', { id: this.form.taxGroupId });
        }
        if (actionName === 'add') {
          if (this.activeTab === 'setup') {
            this.rowData = null;
            this.showHidePartySetupDetails(true);
            this.selectedTaxSectionData.tax_section_id;
          }
        }
      }
    });
  },
  methods: {
    setFormData(data) {
      this.form.taxGroup = data.tax_group;
      this.form.taxGroupId = data.tax_group_id;
      this.form.section = data.section;
      this.form.sectionId = data.tax_section_id;
      this.form.status = data.active;
      this.getTaxSectionById();
    },
    getTaxSectionById() {
      const payload = {
        pagination: {
          _page: this.currentPage - 1,
          _limit: this.perPage
        },
        tax_section_id: this.form.sectionId
      };
      this.$store
        .dispatch('defineTaxSection/getTaxSectionById', payload)
        .then(response => {
          if (response.status === 200) {
            if (response.data.data.page.length > 0) {
              this.taxSectionDetails = response.data.data.page;
              this.totalRows = response.data.data.total_elements;
            } else {
              this.taxSectionDetails[0].tax_section_id = this.form.sectionId;
            }
          }
        })
        .catch(() => {
          alert('Something went Wrong.');
        });
    },
    openTdsCcidModal(vsetCode, index) {
      if (this.taxSectionDetails[this.index].le_id) {
        this.openValueSetModal(vsetCode, index);
      } else {
        alert('Please Select Legal Entity first.');
      }
    },
    openValueSetModal(vsetCode, index) {
      this.vsetCode = vsetCode;
      this.index = index;
      this.showValueSetModal = true;
      if (this.vsetCode === appStrings.VALUESETTYPE.CCID_BY_LE) {
        this.parent_value_set_id = this.taxSectionDetails[this.index].le_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.VENDOR_SITE_VSET) {
        this.parent_value_set_id = this.taxSectionDetails[this.index].vendor_id;
      }
      this.setTimeVsetCode = setTimeout(() => {
        const vSetData = {
          valueSetName: vsetCode,
          multiFlag: null
        };
        this.eventBus.$emit('valueSetCode', vSetData);
      }, 0);
    },
    selectedvalueSet(item) {
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.taxSectionDetails[this.index].le_id = item.value_set_dtl_id;
        this.taxSectionDetails[this.index].le_name = item.value_code;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LOCATION) {
        this.taxSectionDetails[this.index].entity_id = item.value_set_dtl_id;
        this.taxSectionDetails[this.index].entity_name = item.value_code;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.CCID_BY_LE) {
        this.taxSectionDetails[this.index].tds_clearing_ccid_meaning =
          item.value_code;
        this.taxSectionDetails[this.index].tds_clearing_ccid =
          item.value_set_dtl_id;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.VENDOR_SITE_VSET) {
        this.taxSectionDetails[this.index].vendor_site = item.value_code;
        this.taxSectionDetails[this.index].vendor_site_id =
          item.value_set_dtl_id;
      }
    },
    clearVsetValues(vsetCode, index) {
      this.vsetCode = vsetCode;
      if (this.vsetCode === appStrings.VALUESETTYPE.LEGAL_ENTITY_LIST) {
        this.taxSectionDetails[index].le_id = null;
        this.taxSectionDetails[index].le_name = null;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.LOCATION) {
        this.taxSectionDetails[index].entity_id = null;
        this.taxSectionDetails[index].entity_name = null;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.CUSTOMER_NAME) {
        this.taxSectionDetails[index].vendor_name = null;
        this.taxSectionDetails[index].vendor_id = null;
        this.taxSectionDetails[index].vendor_site = null;
        this.taxSectionDetails[index].vendor_site_id = null;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.VENDOR_SITE_VSET) {
        this.taxSectionDetails[index].vendor_site = null;
        this.taxSectionDetails[index].vendor_site_id = null;
      } else if (this.vsetCode === appStrings.VALUESETTYPE.CCID_BY_LE) {
        this.taxSectionDetails[index].tds_clearing_ccid_meaning = null;
        this.taxSectionDetails[index].tds_clearing_ccid = null;
      }
    },
    closeValueSetModal() {
      this.showValueSetModal = false;
    },
    showHidePartyModal(index, flag) {
      setTimeout(() => {
        this.eventBus.$emit('userType', 'VEND');
      }, 0);
      this.index = index;
      this.showPartyModal = flag;
    },
    getSelectedPartyCustomer(item) {
      this.taxSectionDetails[this.index].vendor_name = item.party_name;
      this.taxSectionDetails[this.index].vendor_id = item.party_id;
      this.taxSectionDetails[this.index].vendor_site = null;
      this.taxSectionDetails[this.index].vendor_site_id = null;
      this.getCustomerByPartyId(item.party_id);
      this.showPartyModal = false;
    },
    getCustomerByPartyId(partyId) {
      this.$store
        .dispatch('leaseSchedule/getVendorIdByPartyId', partyId)
        .then(response => {
          if (response.status === 200) {
            const vendorId = response.data.data;
            this.taxSectionDetails[this.index].vendor_id = vendorId;
          }
        });
    },
    addNewRow() {
      if (this.updateMode) {
        this.taxSectionDetails.push({
          tax_section_id: this.form.sectionId,
          tax_section_site_mpng_id: 0,
          le_id: null,
          le_name: null,
          entity_id: null,
          entity_name: null,
          entity_type: 'LOC',
          vendor_id: null,
          vendor_name: null,
          vendor_site_id: null,
          vendor_site: null,
          tds_clearing_ccid: null,
          tds_clearing_ccid_meaning: null
        });
      }
    },
    removeRow(index) {
      this.taxSectionDetails.splice(index, 1);
    },
    addEditTaxSection() {
      if (this.addMode) {
        const payload = {
          active: this.form.status,
          section: this.form.section,
          tax_group_id: this.form.taxGroupId,
          tax_section_id: this.form.sectionId
        };
        this.$store
          .dispatch('defineTaxSection/addEditTaxSection', payload)
          .then(response => {
            this.editMode = false;
            this.updateMode = false;
            this.showAlert = true;
            this.loader = false;
            if (response.status === 201) {
              this.isSuccess = true;
              this.responseMsg = response.data.message;
            } else {
              this.isSuccess = false;
              this.responseMsg = response.response.data.message;
            }
            this.addEditTaxSectionDetails();
          });
      } else {
        this.addEditTaxSectionDetails();
      }
    },
    addEditTaxSectionDetails() {
      const taxSectionDetails = this.taxSectionDetails.map(data => {
        return {
          tax_section_site_mpng_id: data.tax_section_site_mpng_id,
          le_id: data.le_id,
          entity_id: data.entity_id,
          entity_type: data.entity_type,
          tax_section_id: data.tax_section_id,
          tds_clearng_ccid: data.tds_clearing_ccid,
          vendor_id: data.vendor_id,
          vendor_site_id: data.vendor_site_id
        };
      });
      const payload = {
        tax_section_site: taxSectionDetails
      };
      this.$store
        .dispatch('defineTaxSection/addEditTaxSectionDetails', payload)
        .then(response => {
          this.editMode = false;
          this.updateMode = false;
          this.showAlert = true;
          this.loader = false;
          if (response.status === 201) {
            this.isSuccess = true;
            this.responseMsg = response.data.message;
            this.getTaxSectionById();
          } else {
            this.isSuccess = false;
            this.responseMsg = response.response.data.message;
          }
        });
    },
    openTaxGroupList() {
      if (this.editMode) {
        this.showTaxGroupList = true;
      }
    },
    selectTaxGroup(item) {
      this.form.taxGroup = item.tax_group;
      this.form.taxGroupId = item.tax_group_id;
      this.showTaxGroupList = false;
    },
    hideTaxGroupList() {
      this.showTaxGroupList = false;
    },
    selectedTab(tab) {
      this.activeTab = tab;
    },
    getTdsPartySetup() {
      const payload = {
        tax_section_id: this.form.sectionId
      };
      this.loader = true;
      this.$store
        .dispatch('defineTaxSection/getTdsPartySetup', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.tdsPartySetupDetails = response.data.data.page;
          }
        })
        .catch(error => {
          alert(error.message);
          this.loader = false;
        });
    },
    showHidePartySetupDetails(flag) {
      this.addPartySetupDetails = flag;
      this.getTdsPartySetup();
    },
    rowSelected(item) {
      this.addPartySetupDetails = true;
      this.rowData = item;
    }
  },
  beforeDestroy() {
    this.unsubscribe();
  }
};
